import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";

export const Nav = styled.nav`
  background: #fcf7ee;
  opacity: 80%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

export const NavLink = styled(Link)`
  color: #5b4c47;
  font-family: NovaMono;
  font-size: 18px;

  border-style: solid;
  margin: 5px;
  padding: 5px;

  display: block;
  hight: 20px;
  align-items: center;
  text-decoration: none;
  padding: 0 0.5rem;
  height: 100%;
  cursor: pointer;

  &.active {
    color: #a7947a;
  }
  &:hover {
    color: #5b4c47;
    transition: 200ms ease-in;
    font-weight: bold;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #fff;

  @media (max-width: 800px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    front-size: 1.8rem;
    cursor: pointer;
  }
`;
export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;

  @media screen and (max-width: 800px) {
    display: flex;
  }
`;

export const NavBtn = styled.nav`
  display: flex;

  align-items: center;
  margin-right: 24px;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const NavBtnLink = styled(Link)`
  border-radius: 2px;
  background: #a7947a;

  padding: 10px 22px;
  color: #fcf7ee;

  border: none;
  outline: none;
  curser: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #e1d8ce;
    color: #fff;
  }
`;
