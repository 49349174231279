import React from "react";
import { Box, Container, StoryHeader, Paragraph } from "./page_styles";

export default class OurStory extends React.Component {
  render(): React.ReactNode {
    return (
      <Box>
        <Container>
          <StoryHeader>Julia Day spa</StoryHeader>

          <Paragraph>
            Julia Day Spa is an experience like no other. This Manhasset’s
            hidden gem provides a relaxing and intimate spa experience where
            clients are able to relax and be pampered. We provide a variety of
            spa services from manicures and pedicures, facials, waxing, and
            tinting. From our state-of-the-art spa pedicure treatments to our
            customizable facial treatments, clients can immerse themselves in
            our luxurious day spa treatments.
            <br />
            <br />
            <br />
          </Paragraph>

          <StoryHeader>Services Overview</StoryHeader>

          <Paragraph>Manicures &amp; Pedicures</Paragraph>
          <Paragraph>Shellac Manicures</Paragraph>
          <Paragraph>Waxing</Paragraph>
          <Paragraph>Facials</Paragraph>
        </Container>
      </Box>
    );
  }
}
