export type Service = { service: string; price: number; category: string };

export const services: { service: string; price: number; category: string }[] =
  [
    { service: "Spa Manicure", price: 20, category: "handsAndFeet" },
    { service: "Spa Pedicure", price: 50, category: "handsAndFeet" },
    {
      service: "Spa Manicure & Pedicure",
      price: 20,
      category: "handsAndFeet",
    },
    { service: "Glue Manicure", price: 25, category: "handsAndFeet" },
    {
      service: "Manicure or Pedicure polish change",
      price: 15,
      category: "handsAndFeet",
    },

    { service: "Eyebrow Tinting", price: 40, category: "tint" },
    { service: "Lash Tinting", price: 40, category: "tint" },

    { service: "Eyebrows", price: 30, category: "waxing" },
    { service: "Lip", price: 20, category: "waxing" },
    { service: "Chin", price: 20, category: "waxing" },
    {
      service: "Full Face (without eyebrows)",
      price: 45,
      category: "waxing",
    },
    { service: "Sideburns", price: 22, category: "waxing" },
    { service: "Nose", price: 15, category: "waxing" },
    { service: "Underarm", price: 20, category: "waxing" },
    { service: "Half Arm", price: 35, category: "waxing" },
    { service: "Full Arm", price: 40, category: "waxing" },
    { service: "Full Leg", price: 70, category: "waxing" },
    { service: "Half Leg", price: 30, category: "waxing" },
    { service: "Upper Leg", price: 40, category: "waxing" },
    { service: "Thigh & Custom", price: 90, category: "waxing" },
    { service: "Thigh & Bikini (Brazilian)", price: 60, category: "waxing" },
    { service: "Regular Bikini", price: 40, category: "waxing" },
    { service: "Full Leg & Custom", price: 130, category: "waxing" },
    { service: "Full Leg & Bikini", price: 100, category: "waxing" },
    { service: "Half Back", price: 35, category: "waxing" },
    { service: "Full Back", price: 60, category: "waxing" },
    { service: "Chest", price: 55, category: "waxing" },

    { service: "Classic European Facial", price: 130, category: "facials" },
    { service: "Microdermabrasion", price: 180, category: "facials" },
    { service: "HydraFacial Half Hour", price: 120, category: "facials" },
    { service: "HydraFacial One Hour", price: 220, category: "facials" },
    {
      service: "Customizable Image Facials",
      price: 120,
      category: "facials",
    },
    { service: "Oxygen Facial", price: 150, category: "facials" },
    { service: "Signature Peal", price: 150, category: "facials" },
    { service: "Celluma LED Light Therapy", price: 50, category: "facials" },
    { service: "Dermaplaning", price: 90, category: "facials" },
    { service: "Peel", price: 150, category: "facials" },
    { service: "Thalgo Facial Treatment", price: 150, category: "facials" },
    {
      service: "BioCell Anti-Aging Facial Treatment",
      price: 250,
      category: "facials",
    },
    { service: "Custom Image Facials", price: 0, category: "facials" },
    { service: "Vitamin C Facial", price: 0, category: "facials" },
    { service: "Anti-Aging Facial", price: 0, category: "facials" },
    { service: "Clear Cell Acne Facial", price: 0, category: "facials" },
    { service: "Stem Cell Facial", price: 0, category: "facials" },
  ];
