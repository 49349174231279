import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Routes, HashRouter } from "react-router-dom";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import Home from "./pages";
import OurStory from "./pages/our_story";
import HoursLocation from "./pages/hours_location";
import OurServices from "./pages/our_services";
import GiftCards from "./pages/gift_cards";
import OnlineShop from "./pages/online_shop";
import Contact from "./pages/contact";
import BookNow from "./pages/book_now";

function App() {
  return (
    <HashRouter>
      <Header />

      <Routes>
        <Route path="/" element={<OurStory />} />
        <Route path="/OurStory" element={<OurStory />} />
        <Route path="/hoursLocation" element={<HoursLocation />} />
        <Route path="/ourServices" element={<OurServices />} />
        <Route path="/giftCards" element={<GiftCards />} />
        <Route path="/onlineShop" element={<OnlineShop />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/bookNow" element={<BookNow />} />
      </Routes>

      <Footer />
    </HashRouter>
  );
}

export default App;
