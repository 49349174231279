import React from 'react'

const Contact = () => {
    return (
        <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh'
        }}>
            <h1>Contact</h1>

        </div>
    )
}

export default Contact