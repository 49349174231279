import React from 'react'

const OnlineShop = () => {
    return (
        <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh'
        }}>
            <h1>Online Shop</h1>

        </div>
    )
}

export default OnlineShop