import * as React from "react";
import {
  Box,
  Container,
  Column,
  FooterLink,
  OpenHours,
  Header,
  FollowUs,
  ContactUs,
  Link,
} from "./footer-style";
import emailImg from "../../img/email.png";
import phoneImg from "../../img/phone.png";
import facebookImg from "../../img/facebook.png";
import instagramImg from "../../img/instagram.png";
import yelpImg from "../../img/yelp.png";

type Props = {};
type State = {};

export default class Footer extends React.Component<Props, State> {
  public constructor(props: Props, state: State) {
    super(props);
  }

  private daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  private openHours = [
    "Closed",
    "10:00 AM - 6:00 PM",
    "10:00 AM - 6:00 PM",
    "10:00 AM - 6:00 PM",
    "10:00 AM - 6:00 PM",
    "10:00 AM - 6:00 PM",
    "By Appointment Only",
  ];

  private getOpenHours(): JSX.Element[] {
    let hours: JSX.Element[] = [];
    for (let i = 0; i < 7; ++i) {
      hours.push(<div key={this.daysOfWeek[i]}>{this.daysOfWeek[i]}</div>);
      hours.push(<div key={i}>{this.openHours[i]}</div>);
    }
    return hours;
  }

  private getImage(img: string, title: string): JSX.Element {
    return (
      <img
        src={img}
        alt={`${title}: `}
        width={25}
        height={25}
        title={title}
        style={{
          float: "left",
          verticalAlign: "center",
        }}
      />
    );
  }

  render(): React.ReactNode {
    return (
      <Box>
        <Container>
          <Column>
            <ContactUs>
              <div>
                <h2>Julia Say Spa</h2>
              </div>
              <div>1532 Northern Blvd</div>
              <div>Manhasset, NY 11030</div>
              <div></div>
              <Link>
                <FooterLink href="tel: 5168695900">
                  {this.getImage(phoneImg, "Phone")}&nbsp;516-869-5900
                </FooterLink>
              </Link>
              <Link>
                <FooterLink href="mailto: dayspa@juliadayspa.com">
                  {this.getImage(emailImg, "Email")}&nbsp;dayspa@juliadayspa.com
                </FooterLink>
              </Link>
            </ContactUs>
          </Column>
          <Column>
            <FollowUs>
              <div>
                <h2>Follow Us</h2>
              </div>
              <Link>
                <FooterLink href="https://www.facebook.com/juliadayspa">
                  {this.getImage(facebookImg, "Facebook")}
                  &nbsp;facebook.com/juliadayspa
                </FooterLink>
              </Link>
              <Link>
                <FooterLink href="https://www.instagram.com/julia_day_spa">
                  {this.getImage(instagramImg, "Instagram")}
                  &nbsp;instagram.com/julia_day_spa
                </FooterLink>
              </Link>
              <Link>
                <FooterLink href="https://www.yelp.com/biz/julia-day-spa-of-manhasset-manhasset">
                  {this.getImage(yelpImg, "Yelp")}
                  &nbsp;yelp.com/biz/julia-day-spa-of-manhasset-manhasset
                </FooterLink>
              </Link>
            </FollowUs>
          </Column>
          <Column>
            <OpenHours>
              <Header>
                <h2>Hours of Operation</h2>
              </Header>
              {this.getOpenHours()}
            </OpenHours>
          </Column>
        </Container>
        <div></div>
      </Box>
    );
  }
}
