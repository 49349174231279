import React from "react";
import image from "../img/hoursNew.png";

import { Box, Container, Row, Column } from "./page_styles";

const HoursLocation = () => {
  return (
    <Box
      style={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <iframe
        title={"fr"}
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3020.552966187166!2d-73.690006!3d40.79384100000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c288f83a22f6d9%3A0x71810c3c2d4be154!2sJulia+Day+Spa+of+Manhasset!5e0!3m2!1sen!2sus!4v1406416103629"
        style={{
          width: "800px",
          height: "600px",
          border: "0",
          padding: "20px",
        }}
        seamless
      ></iframe>
    </Box>
  );
};

export default HoursLocation;
