import React, { useEffect } from 'react'

function BookNow () {

    useEffect(() => {
        window.location.href = "http://juliadayspa.com";
      }, []);

    return (
        <div>
        
        </div>
    );
}

export default BookNow
