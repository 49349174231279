import styled from "styled-components";

export const Box = styled.div`
  background: #a7947a;
  padding: 20px 10px;
  display: grid;
  grid-template-columns: 800px auto;
  @media (max-width: 800px) {
    display: flex;
    padding: 5px 2px;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 240px 280px 270px;
  grid-gap: 40px;
  justify-content: left;
  margin: 0 auto;
  max-width: 1000px;
  @media (max-width: 800px) {
    grid-template-columns: 280px 280px;
    grid-gap: 5px;
  }
`;

export const Column = styled.div`
  display: inline-block;
  flex-direction: column;
  text-align: left;
  color: #fcf7ee;
  margin-left: 0px;
`;

export const FooterLink = styled.a`
  color: #fcf7ee;
  margin-bottom: 0px;
  font-size: 18px;
  font-family: NovaMono;
  text-decoration: none;

  &:hover {
    color: #5b4c47;
    transition: 200ms ease-in;
  }
`;

export const Header = styled.div`
  grid-column-start: 1;
  grid-column-end: span 2;
`;

export const OpenHours = styled.div`
  display: grid;
  grid-template-columns: 100px 170px;
  grid-template-rows: repeat(20px);
`;

export const FollowUs = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 270px;
  grid-template-rows: repeat(40px);
`;

export const ContactUs = styled.div`
  display: grid;
  grid-row-gap: 5px;
  grid-template-columns: 230px;
  grid-template-rows: repeat(40px);
`;

export const Link = styled.div`
  background: #a08b72;
  border-radius: 100px;
  padding: 7px;
`;
