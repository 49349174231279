import React from "react";
import image from "../img/servicesNew.png";
import {
  Box,
  Container,
  RowService,
  ColumnService,
  ServiceItem,
  ServicePrice,
  Header,
} from "./page_styles";
import { services, Service } from "./services";

export default class OurServices extends React.Component {
  handsFeet = {
    "Spa Manicure": "20.00",
    "Spa Pedicure": "50.00",
  };

  public ServiceElement(category: string, services: Service[]): JSX.Element[] {
    const elements: JSX.Element[] = [];
    for (const item of services.filter(
      (val: Service) => val.category === category
    )) {
      const price: string =
        item.price === 0 ? "Call" : `$${item.price.toString()}.00`;
      elements.push(<ServiceItem>{item.service}</ServiceItem>);
      elements.push(<ServicePrice>{price}</ServicePrice>);
    }
    return elements;
  }

  public Placeholder(rows: number): JSX.Element[] {
    const elements: JSX.Element[] = [];
    for (let i = 0; i < rows; ++i) {
      elements.push(<div></div>);
      elements.push(<div></div>);
    }
    return elements;
  }

  render(): React.ReactNode {
    return (
      <div
        style={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Box>
          <div></div>
          <Container>
            <ColumnService>
              <RowService>
                <Header>Hands &amp; Feet</Header>
                {this.ServiceElement("handsAndFeet", services)}
                <Header>Tinting</Header>
                {this.ServiceElement("tint", services)}
                {this.Placeholder(10)}
              </RowService>

              <RowService>
                <Header>Waxing</Header>
                {this.ServiceElement("waxing", services)}
              </RowService>
              <RowService>
                <Header>Facials &amp; Skin Care</Header>
                {this.ServiceElement("facials", services)}
                {this.Placeholder(8)}
              </RowService>
            </ColumnService>
          </Container>
        </Box>
      </div>
    );
  }
}
