import React from "react";
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from "./header-style";
import logoImg from "../../img/julialogo.png";

type Props = {};
type State = {};

export default class Header extends React.Component<Props, State> {
  private getImage(img: string, title: string): JSX.Element {
    return (
      <img
        src={img}
        alt={`${title}: `}
        width={120}
        height={92}
        title={title}
        style={{
          float: "left",
          verticalAlign: "center",
        }}
      />
    );
  }

  render(): React.ReactNode {
    return (
      <Nav>
        <NavMenu>
          <NavLink to="/">{this.getImage(logoImg, "Logo")}</NavLink>
          <NavLink to="/OurStory">Our Story</NavLink>
          <NavLink to="/hoursLocation">Hours and Location</NavLink>
          <NavLink to="/ourServices">Our Services</NavLink>
          <NavLink to="/giftCards">Gift Cards</NavLink>
          <NavLink to="/onlineShop">Online Shop</NavLink>
          <NavLink to="/contact">Contact</NavLink>

          <NavLink to="/bookNow">Book Now</NavLink>
        </NavMenu>
      </Nav>
    );
  }
}
