import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  padding: 0;
  margin: 0;
  background-color: #f5e6d2;
  border-style: solid none none none;
  border-color: #f4d8b2;
`;

export const Container = styled.div`
  position: relative;
  max-width: 1000px;
  margin: 20px;
  padding: 0;
  @media screen and (max-width: 800px) {
    margin: 2px;
  }
`;

export const Paragraph = styled.p`
  font-size: 20px;
  text-align: left;
`;

export const StoryHeader = styled.h1`
  text-align: left;
`;

export const RowOurStory = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill);
  grid-gap: 20px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  color: #5b4c47;
  margin-left: 30px;
  border-style: solid;
  border-width: medium;
`;

export const ColumnOurStory = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #5b4c47;
  //margin-left: 400px;
`;

export const Row = styled.div`
  display: grid;
  justify-content: right;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 20px;
  line-height: 0.3;
`;

export const ColumnService = styled.div`
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: minmax(150px, auto) minmax(150px, auto) minmax(
      150px,
      auto
    );
  color: #5b4c47;
`;

export const RowService = styled.div`
  display: grid;
  grid-template-columns: minmax(auto 250px) minmax(auto 150px);
  grid-row-gap: 10px;
`;

export const Header = styled.div`
  grid-column-start: 1;
  grid-column-end: span 2;
  font-size: 30px;
  font-weight: bold;
`;

export const ServicePrice = styled.div`
  font-size: 15px;
  font-weight: bold;
  text-align: right;
`;

export const ServiceItem = styled.div`
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  &:hover {
    transition: all 0.2s ease-in-out;
    color: #0000ff;
    cursor: pointer;
  }
`;
